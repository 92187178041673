import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import { CSSTransition } from 'react-transition-group';

const ProductImages = ({ children, items }) => {
  const {
    currentTubIndex,
    currentCategory,
    productImageIndex,
    setProductImageIndex
  } = useContext(FunnelContext);

  if (productImageIndex === 0 && currentCategory === 'onetime') {
    setProductImageIndex(1);
  }

  if (productImageIndex === 1 && currentCategory === 'subscriptions') {
    setProductImageIndex(0);
  }

  const duration = 300;

  return children.map((child, index) => {
    const item = items[index] || [];
    let show = false;

    item?.showIn.map(s => {
      if (
        s.currentTubIndex === currentTubIndex &&
        s.currentCategory === currentCategory &&
        index === productImageIndex
      ) {
        show = true;
      }
      return null;
    });

    return (
      <CSSTransition
        key={index}
        in={show}
        timeout={duration}
        classNames="fade"
        unmountOnExit
      >
        {child}
      </CSSTransition>
    );
  });
};

export default ProductImages;
